<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ resellerInvoice.code }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_RESELLER_INVOICES)"
            @click="deleteResellerInvoice"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_RESELLER_INVOICES)"
            @click="editResellerInvoice"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ resellerInvoice.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("RESELLER_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd>
            {{ resellerInvoice.expiration_time | moment("LLLL") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="resellerInvoice.reseller" />
          </dd>
        </dl>

        <dl class="row" v-if="resellerInvoice.resellerInvoice">
          <dt>{{ $t("COMMON.RESELLER_ORDER") }}</dt>
          <dd>
            <object-link :object="resellerInvoice.resellerInvoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.RECIPIENT") }}</dt>
          <dd>
            <object-link :object="resellerInvoice.recipient" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <reseller-invoice-status-badge :resellerInvoice="resellerInvoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.TOTAL") }}</dt>
          <dd v-if="resellerInvoice.pricing">
            <span> {{ $formatCurrency(resellerInvoice.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd>
            <span>
              {{
                $formatCurrency(
                  resellerInvoice.total_paied ? resellerInvoice.total_paied : 0
                )
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt v-if="resellerInvoice.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd v-if="resellerInvoice.excerpt">
            <div v-html="resellerInvoice.excerpt"></div>
          </dd>
        </dl>

        <div class="col-12 mb-3 mt-3">
          <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
        </div>

        <dl
          class="row"
          v-if="
            resellerInvoice.billing_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ resellerInvoice.billing_lastname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            resellerInvoice.billing_entity_type ===
            BILLING_ENTITY_TYPE_INDIVIDUAL
          "
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ resellerInvoice.billing_firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            resellerInvoice.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY
          "
        >
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ resellerInvoice.billing_company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ resellerInvoice.billing_country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ resellerInvoice.billing_state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ resellerInvoice.billing_city }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ resellerInvoice.billing_zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ resellerInvoice.billing_address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ resellerInvoice.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";
import ResellerInvoiceStatusBadge from "./ResellerInvoiceStatusBadge.vue";

export default {
  name: "resellerInvoice-view-global",

  components: {
    ResellerInvoiceStatusBadge,
  },

  props: ["resellerInvoice"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {
    editResellerInvoice() {
      this.$emit("onEditResellerInvoice", this.resellerInvoice);
    },
    deleteResellerInvoice() {
      this.$emit("onDeleteResellerInvoice", this.resellerInvoice);
    },
  },

  mounted() {},

  watch: {
    resellerInvoice(resellerInvoice) {},
  },
};
</script>
